<template>
  <div class="app-container">
    <Header />
    <main class="main-content">
      <div class="scroll-container">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import { onMounted, onBeforeUnmount } from "vue";
import { provideModalState } from "@/hooks/useModalState";

export default {
  components: {
    Header,
  },
  setup() {
    provideModalState();
  },
};
</script>

<style>
@import './assets/styles/styles.scss';

:root,
body,
html {
  overscroll-behavior: none;
}

.scroll-container {
  position: relative;
}

.image-grid {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(15vh - 100px);
  min-height: 70px;
  scroll-padding-left: 0;
  position: relative;
  z-index: 5;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.image-grid::-webkit-scrollbar {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>