<template>
  <div>
    <div v-if="loading" class="projects-spinner-container">
      <div class="spinner"></div>
    </div>
    <Projects v-else :projects="sortedProjects" />
  </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue';
import Projects from "@/components/Projects.vue";
import {useProjectStore} from '@/stores/projects';

const loading = ref(true);
const projectStore = useProjectStore();

// Use computed property to access sorted projects from the store
const sortedProjects = computed(() => {
  // First check if the projects exist in the store
  if (projectStore.sortedProjects) {
    return projectStore.sortedProjects;
  }
  return [];
});

onMounted(async () => {
  const startTime = Date.now();
  const MINIMUM_LOADING_TIME = 500;

  try {
    // If projects are already in Pinia store, use them
    if (projectStore.projects) {
      loading.value = false;
      return;
    }

    // Fetch projects through the store
    await projectStore.loadProjects();

    // Ensure minimum loading time for better UX
    const elapsedTime = Date.now() - startTime;
    const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

    if (remainingTime > 0) {
      await new Promise(resolve => setTimeout(resolve, remainingTime));
    }
  } catch (error) {
    console.error('Error loading projects:', error);
  } finally {
    loading.value = false;
  }
});
</script>