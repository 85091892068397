// api.js
import axios from 'axios';
import {imageLoader} from "@/utils/imageLoader";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://192.168.189.13:1337',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

// api.js
export const fetchProjects = async () => {
  try {
    // Try to get from cache
    const cached = localStorage.getItem('projects');
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      const isValid = Date.now() - timestamp < 24 * 60 * 60 * 1000;
      if (isValid) return data;
    }

    // No cache or expired - fetch fresh
    const response = await apiClient.get('/api/projects?populate[images][populate]=*&pagination[pageSize]=100');

    if (response.status === 200) {
      // Try to cache, but don't break if it fails
      try {
        localStorage.setItem('projects', JSON.stringify({
          data: response.data.data,
          timestamp: Date.now()
        }));
      } catch (e) {
        // Cache failed, no big deal - continue with fresh data
      }
      return response.data.data;
    }
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};


export const getFullUrl = (path) => {
  if (!path) return '';
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  return `${apiClient.defaults.baseURL}/${cleanPath}`;
};


export const sortProjectsByDate = (projects) => {
  const getYear = (date) => {
    if (!date) return 0;
    const years = date.split('-').map((year) => parseInt(year, 10));
    return Math.min(...years);
  };

  return [...projects].sort((a, b) => getYear(a.attributes.date) - getYear(b.attributes.date));
};

export default apiClient;
