import { getFullUrl } from '@/api';

export const findAdjacentProject = (projects, currentProject, direction) => {
    const currentIndex = projects.findIndex((p) => p.id === currentProject.id);
    const newIndex = (currentIndex + direction + projects.length) % projects.length;
    return projects[newIndex];
};

export const getImageUrl = (image, isHighRes = false) => {
    const formats = image.attributes.formats;
    if (isHighRes) {
        return getFullUrl(
            formats.large?.url ||
            formats.small?.url ||
            formats.thumbnail.url
        );
    }
    return getFullUrl(formats.thumbnail.url);
};

export async function loadHighResImages(project) {
    const highResImagesLoaded = {};

    const promises = project.attributes.images.data.map((image) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = getFullUrl(
                image.attributes.formats.large?.url ||
                image.attributes.formats.medium?.url ||
                image.attributes.formats.small?.url
            );
            img.onload = () => {
                highResImagesLoaded[image.id] = true;
                resolve();
            };
            img.onerror = () => {
                console.error(`Error loading image: ${img.src}`);
                resolve();
            };
        });
    });

    await Promise.all(promises);
    return highResImagesLoaded;
}

export const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = reject;
    });
};

export async function loadProjectThumbnails(
    project,
    imageLoader,
    loadingStatus,
    visibleProjects
) {
    // If we've already loaded or are loading, skip
    if (loadingStatus.get(project.id) === true) return;

    // Mark loading in progress
    loadingStatus.set(project.id, false);

    // Grab just the first 10 images
    const images = project.attributes.images.data.slice(0, 10);

    try {
        // Load each thumbnail in parallel
        await Promise.all(
            images.map((image) => {
                const thumbUrl = image.attributes.formats.thumbnail.url;
                return imageLoader.loadImage(thumbUrl);
            })
        );

        // Mark loaded
        loadingStatus.set(project.id, true);

        // Once loaded, add this project ID to visibleProjects so it fades in
        visibleProjects.value.add(project.id);
    } catch (error) {
        console.error(`Error loading thumbnails for project ${project.id}:`, error);
        // Even if there's an error, we still show the project
        visibleProjects.value.add(project.id);
    }
}

export const sortProjects = (projects) => {
    return [...projects].sort((a, b) => {
        const getYear = (date) => {
            if (!date) return 0;
            const years = date.split('-').map((year) => parseInt(year, 10));
            return Math.min(...years);
        };
        return getYear(a.attributes.date) - getYear(b.attributes.date);
    });
};

export const imageLoader = {
    cache: new Map(),
    loadingPromises: new Map(),

    loadImage(path) {
        const fullUrl = getFullUrl(path);

        if (this.cache.has(fullUrl)) {
            return Promise.resolve(this.cache.get(fullUrl));
        }

        if (this.loadingPromises.has(fullUrl)) {
            return this.loadingPromises.get(fullUrl);
        }

        const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                this.cache.set(fullUrl, fullUrl);
                this.loadingPromises.delete(fullUrl);
                resolve(fullUrl);
            };
            img.onerror = () => {
                this.loadingPromises.delete(fullUrl);
                reject(new Error(`Failed to load image: ${fullUrl}`));
            };
            img.src = fullUrl;
        });

        this.loadingPromises.set(fullUrl, promise);
        return promise;
    }
};