// utils/cache.js
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export const cacheKeys = {
    PROJECTS: 'cached_projects',
};

export class StorageManager {
    static async estimateStorageUsage() {
        if ('storage' in navigator && 'estimate' in navigator.storage) {
            const estimate = await navigator.storage.estimate();
            return {
                usage: estimate.usage,
                quota: estimate.quota,
                available: estimate.quota - estimate.usage
            };
        }
        return null;
    }

    static set(key, data) {
        try {
            // Store without timestamp first to test size
            const testStore = { data };
            const serialized = JSON.stringify(testStore);

            // If we can store this, add timestamp and store for real
            const cacheData = {
                data,
                timestamp: Date.now()
            };

            localStorage.setItem(key, JSON.stringify(cacheData));
            return true;
        } catch (error) {
            // If we hit quota issues, clean up and don't cache
            console.warn(`Storage quota reached, proceeding without caching`);
            this.clear(key);
            return false;
        }
    }

    static get(key) {
        try {
            const cached = localStorage.getItem(key);
            if (!cached) return null;

            const { data, timestamp } = JSON.parse(cached);
            const isExpired = Date.now() - timestamp > CACHE_DURATION;

            if (isExpired) {
                this.clear(key);
                return null;
            }

            return data;
        } catch (error) {
            this.clear(key);
            return null;
        }
    }

    static clear(key) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.warn('Error clearing cache:', error);
        }
    }

    static clearAll() {
        try {
            localStorage.clear();
        } catch (error) {
            console.warn('Error clearing all cache:', error);
        }
    }
}