<template>
  <div class="index">
    <!-- Initial spinner while fetching projects -->
    <div v-if="isInitialLoading" class="global-spinner-container">
      <div class="spinner"></div>
      <div class="loading-text">Loading projects...</div>
    </div>

    <div class="projects" :class="{ expanded: isExpanded }" v-else>
      <div
          class="projects-container"
          :class="{ expanded: isExpanded }"
          @keydown.esc="() => isExpanded = false"
          @keydown.left="() => navigate('previous')"
          @keydown.right="() => navigate('next')"
          tabindex="0"
          ref="containerRef"
      >
        <div
            v-for="(project, pIndex) in projectStore.visibleProjects"
            :key="project.id"
            class="project-row"
            :class="[
              projectClasses(pIndex),
              {'hidden-project': !projectLoadingStatus[project.id]?.thumbnailsLoaded}
            ]"
            :data-project-id="project.id"
        >
          <!-- Project thumbnail loading spinner -->
          <div v-if="projectLoadingStatus[project.id]?.isLoading && !projectLoadingStatus[project.id]?.thumbnailsLoaded"
               class="project-loading-spinner">
            <div class="spinner"></div>
            <div class="loading-text">Loading project...</div>
          </div>

          <!-- Project content -->
          <div class="project-content"
               v-if="projectLoadingStatus[project.id]?.thumbnailsLoaded"
               :class="{ 'fade-in': projectLoadingStatus[project.id]?.thumbnailsLoaded }">

            <ProjectInfo
                :name="project.attributes.name"
                :date="project.attributes.date"
                :description="project.attributes.description"
                @click-header="() => enhancedToggleExpand(pIndex)"
                :class="infoClasses(pIndex)"
                :showDescription="true"
            />

            <!-- Critical images loading spinner -->
            <div v-if="projectStore.isProjectLoading(project.id)" class="spinner-container">
              <div class="spinner"></div>
            </div>

            <div
                class="images-scroll-container"
                @wheel.passive="handleWheel"
                @scroll="handleScroll"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @touchend="handleTouchEnd"
                ref="scrollContainerRef"
                :class="{ minimized: !isExpanded || currentProjectIndex !== pIndex }"
            >
              <!-- Images container -->
              <div class="images-container" :class="{ 'fade-in': projectLoadingStatus[project.id]?.thumbnailsLoaded }">
                <!-- Image wrappers -->
                <div
                    v-for="image in getProjectVisibleImages(project.id)"
                    :key="image.id"
                    class="image-wrapper"
                    :class="{
                        expanded: isExpanded && currentProjectIndex === pIndex,
                        active: isExpanded && currentProjectIndex === pIndex &&
                                getProjectVisibleImages(project.id).indexOf(image) === currentImageIndex
                    }"
                    @click="(event) => handleImageSelection(pIndex, getProjectVisibleImages(project.id).indexOf(image), event)"
                >
                  <img
                      :src="getImageUrl(image, isExpanded && currentProjectIndex === pIndex)"
                      :alt="project.attributes.name"
                      class="gallery-image"
                      :class="{ expanded: isExpanded && currentProjectIndex === pIndex }"
                  />
                </div>

                <!-- Loading spinner for additional images -->
                <div v-if="projectLoadingStatus[project.id]?.isLoading && projectLoadingStatus[project.id]?.thumbnailsLoaded"
                     class="inline-spinner">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Infinite scroll trigger -->
        <div ref="loadMoreTrigger" class="load-more-trigger"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, onUnmounted, nextTick, reactive, computed, watch } from 'vue';
import ProjectInfo from '@/components/projects/ProjectInfo.vue';
import { useGallery } from '@/hooks/useGallery';
import { useProjectStore } from '@/stores/projects';
import { preloadProjectImages } from '@/utils/projectsLoader';

const projectStore = useProjectStore();
const containerRef = ref(null);
const scrollContainerRef = ref(null);
const loadMoreTrigger = ref(null);
const isInitialLoading = ref(true);
const savedScrollPosition = ref(0);
const projectVisibleImages = reactive({});
const projectLoadingStatus = reactive({});
const clickedImageRect = ref(null);

// Gallery hooks
const {
  isExpanded,
  currentProjectIndex,
  currentImageIndex,
  projectClasses,
  infoClasses,
  getImageUrl,
  navigate,
  toggleExpand,
  handleResize,
  handleWheel,
  handleScroll,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  disableScrollAnimation,
  enableScrollAnimation
} = useGallery(null, scrollContainerRef);

// Helper functions
const getProjectVisibleImages = (projectId) => {
  return projectVisibleImages[projectId] || [];
};

const getProjectAllImages = (project) => {
  if (!project?.attributes?.images?.data) return [];
  return project.attributes.images.data;
};

// Enhanced toggle expand
const enhancedToggleExpand = (pIndex) => {
  if (!isExpanded.value) {
    savedScrollPosition.value = window.pageYOffset || document.documentElement.scrollTop;
  }
  toggleExpand(pIndex);
  if (isExpanded.value === false) {
    nextTick(() => window.scrollTo(0, savedScrollPosition.value));
  }
};

// Load remaining images
const loadRemainingImages = async (project) => {
  if (!project || !project.id) return;

  const status = projectLoadingStatus[project.id];
  const allImages = getProjectAllImages(project);

  if (status.currentIndex >= allImages.length) {
    status.isLoading = false;
    return;
  }

  try {
    // Get remaining images
    const remainingImages = allImages.slice(status.currentIndex);

    // Show loading indicator
    status.isLoading = true;

    // Load remaining images in smaller batches with fade-in
    const BATCH_SIZE = 5;
    for (let i = 0; i < remainingImages.length; i += BATCH_SIZE) {
      const batch = remainingImages.slice(i, i + BATCH_SIZE);

      // Preload this batch
      await preloadProjectImages(batch);

      // Get current image count before adding new ones
      const currentCount = projectVisibleImages[project.id].length;

      // Add images to visible array
      projectVisibleImages[project.id].push(...batch);

      // Update status
      status.currentIndex += batch.length;

      // Small delay between batches for visibility
      if (i + BATCH_SIZE < remainingImages.length) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }

    // All done
    status.isLoading = false;
  } catch (error) {
    console.error(`Error in loadRemainingImages for ${project.attributes?.name || project.id}:`, error);
    status.isLoading = false;
  }
};

// Handle image selection
const handleImageSelection = async (projectIndex, imageIndex, event) => {
  if (isExpanded.value) return;
  savedScrollPosition.value = window.pageYOffset || document.documentElement.scrollTop;
  currentProjectIndex.value = projectIndex;
  currentImageIndex.value = imageIndex;

  if (event?.target) clickedImageRect.value = event.target.getBoundingClientRect();

  try {
    disableScrollAnimation();
    const project = projectStore.visibleProjects[projectIndex];
    if (!projectStore.projectsWithLoadedCriticalImages.has(project.id)) {
      await projectStore.loadCriticalImages(project, imageIndex);
    }

    isExpanded.value = true;
    nextTick(() => {
      const expandedContainer = document.querySelector('.project-row.expanded .images-scroll-container');
      if (expandedContainer) {
        const imageElements = expandedContainer.querySelectorAll('.image-wrapper');
        if (imageElements[imageIndex]) {
          const targetLeft = imageElements[imageIndex].offsetLeft;
          expandedContainer.scrollLeft = targetLeft;
          setTimeout(() => expandedContainer.scrollLeft = targetLeft, 10);
        }
      }
      setTimeout(() => enableScrollAnimation(), 50);
    });
  } catch (error) {
    console.error('Error during image selection:', error);
    enableScrollAnimation();
  }
};

// Observer setup
const infiniteScrollObserver = ref(null);
let projectsObserver = null;
let lazyLoadingObserver = null;

// Set up lazy loading
const setupLazyProjectLoading = () => {
  if (lazyLoadingObserver) lazyLoadingObserver.disconnect();
  nextTick(() => {
    const projectElements = document.querySelectorAll('.project-row');
    if (!projectElements.length) return;

    lazyLoadingObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const projectId = entry.target.getAttribute('data-project-id');
          const projectIndex = projectStore.visibleProjects.findIndex(p => p.id === projectId);
          if (projectIndex === -1) return;

          const nextIndex = projectIndex + 1;
          if (nextIndex < projectStore.visibleProjects.length) {
            const nextProject = projectStore.visibleProjects[nextIndex];
            if (nextProject && !projectLoadingStatus[nextProject.id]?.initialBatchLoaded) {
              loadInitialThumbnails(nextProject);
              lazyLoadingObserver.unobserve(entry.target);
            }
          }
        }
      });
    }, { threshold: 0.3, rootMargin: '100px' });

    for (let i = 0; i < Math.min(projectElements.length, 5); i++) {
      lazyLoadingObserver.observe(projectElements[i]);
    }
  });
};

// Set up infinite scroll
const setupInfiniteScroll = () => {
  if (infiniteScrollObserver.value) infiniteScrollObserver.value.disconnect();
  nextTick(() => {
    if (!loadMoreTrigger.value) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && typeof projectStore.loadMoreProjects === 'function') {
        projectStore.loadMoreProjects();
      }
    }, { threshold: 0.1 });
    observer.observe(loadMoreTrigger.value);
    infiniteScrollObserver.value = observer;
  });
};

// Watch for changes to visibleProjects
watch(() => projectStore.visibleProjects.length, (newLength, oldLength) => {
  if (newLength !== oldLength) {
    nextTick(() => {
      setupInfiniteScroll();
      setupLazyProjectLoading();
    });
  }
});

// Preload adjacent projects
watch([isExpanded, currentProjectIndex], ([newExpanded, newIndex]) => {
  if (newExpanded && newIndex !== null) {
    const currentProject = projectStore.visibleProjects[newIndex];
    if (currentProject) {
      nextTick(() => {
        projectStore.preloadAdjacentProject('next', currentProject.id);
        projectStore.preloadAdjacentProject('previous', currentProject.id);
      });
    }
  }
});

// Load initial thumbnails
const loadInitialThumbnails = async (project) => {
  if (!project || !project.id) return;

  // Skip if already loaded
  if (projectLoadingStatus[project.id]?.thumbnailsLoaded) {
    return;
  }

  // Initialize status if needed
  if (!projectLoadingStatus[project.id]) {
    projectLoadingStatus[project.id] = reactive({
      isLoading: true,
      currentIndex: 0,
      thumbnailsLoaded: false,
      initialBatchLoaded: false
    });
  }

  // Initialize visible images if needed
  if (!projectVisibleImages[project.id]) {
    projectVisibleImages[project.id] = [];
  }

  const status = projectLoadingStatus[project.id];
  const allImages = getProjectAllImages(project);

  if (!allImages || allImages.length === 0) {
    status.isLoading = false;
    status.thumbnailsLoaded = true;
    status.initialBatchLoaded = true;
    return;
  }

  try {
    const initialBatchSize = Math.min(10, allImages.length);
    const initialImages = allImages.slice(0, initialBatchSize);

    // Preload images
    await preloadProjectImages(initialImages);

    // Add to visible images
    projectVisibleImages[project.id].push(...initialImages);

    // Update status
    status.currentIndex = initialBatchSize;
    status.thumbnailsLoaded = true;
    status.initialBatchLoaded = true;

    // Queue remaining images loading
    if (allImages.length > initialBatchSize) {
      loadRemainingImages(project);
    } else {
      status.isLoading = false;
    }
  } catch (error) {
    console.error(`Error loading thumbnails:`, error);
    // Ensure project is visible even on error
    status.thumbnailsLoaded = true;
    status.initialBatchLoaded = true;
    status.isLoading = false;
  }
};

// Load projects sequentially
const loadProjectsSequentially = async () => {
  // Get all projects
  const allProjects = projectStore.visibleProjects;
  console.log(`Loading all ${allProjects.length} projects sequentially`);

  // Initialize all projects at once
  allProjects.forEach(project => {
    if (!project || !project.id) return;

    // Skip if already loaded
    if (projectLoadingStatus[project.id]?.thumbnailsLoaded) {
      return;
    }

    projectLoadingStatus[project.id] = reactive({
      isLoading: true,
      currentIndex: 0,
      thumbnailsLoaded: false,
      initialBatchLoaded: false
    });

    projectVisibleImages[project.id] = [];
  });

  // Load each project one at a time in sequence
  for (let i = 0; i < allProjects.length; i++) {
    const project = allProjects[i];
    if (!project || !project.id) continue;

    // Skip if already loaded
    if (projectLoadingStatus[project.id]?.thumbnailsLoaded) {
      continue;
    }

    console.log(`Loading project ${i + 1}/${allProjects.length}: ${project.attributes?.name || project.id}`);

    try {
      // Load thumbnails
      await loadInitialThumbnails(project);

      // Only brief pause between projects to prevent UI freeze
      await new Promise(resolve => setTimeout(resolve, 10));
    } catch (err) {
      console.error(`Error loading project ${project.id}:`, err);
    }
  }

  console.log("All projects loaded sequentially");

  // Setup observers for any future projects
  setupInfiniteScroll();
  setupLazyProjectLoading();
};

// Watch for new projects
const watchForNewProjects = () => {
  const container = document.querySelector('.projects-container');
  if (!container) return null;
  const observer = new MutationObserver(() => {
    const newProjects = projectStore.visibleProjects.filter(project =>
        project && !projectLoadingStatus[project.id]?.initialBatchLoaded
    );
    if (newProjects.length > 0) setupLazyProjectLoading();
  });
  observer.observe(container, { childList: true, subtree: true });
  return observer;
};

// Component lifecycle
onMounted(async () => {
  isInitialLoading.value = true;

  // Check if projects exist in the store
  if (!projectStore.projects) {
    await projectStore.loadProjects();
  }

  // Initialize visible projects if needed
  if (projectStore.sortedProjects.length > 0 && projectStore.visibleProjects.length !== projectStore.sortedProjects.length) {
    projectStore.visibleProjects = [...projectStore.sortedProjects];
  }

  isInitialLoading.value = false;

  // Load projects sequentially
  nextTick(async () => {
    await loadProjectsSequentially();
  });

  projectsObserver = watchForNewProjects();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  if (projectsObserver) projectsObserver.disconnect();
  if (lazyLoadingObserver) lazyLoadingObserver.disconnect();
});

onUnmounted(() => {
  if (infiniteScrollObserver.value) {
    infiniteScrollObserver.value.disconnect();
    infiniteScrollObserver.value = null;
  }
});
</script>

<style lang="scss" scoped>
.projects {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.expanded) {
    margin-top: 35vh;
  }
}

.global-spinner-container {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.9);

  .loading-text {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
  }

  .spinner {
    width: 50px;
    height: 50px;
    border-width: 4px;
  }
}

.project-loading-spinner {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .loading-text {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
  }
}

.projects-container {
  display: grid;
  position: relative;
  outline: none;
  width: 100%;
  overflow: hidden;
  gap: 20px;

  &.expanded {
    overflow: hidden; /* Contain overflow when expanded */
    height: calc(100vh - 25px);
  }
}

.thumbnails-spinner-container {
  height: calc(15vh - 100px); /* Exactly match the images-container height */
  min-height: 70px; /* Same as images-container */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-row {
  transition: all 300ms ease-in-out;
  opacity: 1;

  &.expanded {
    overflow: hidden;
    left: 0;
    width: 100%;
    position: fixed;
    margin: 0;
    z-index: 9999;
    background: white;
    height: calc(100vh - 25px); /* Subtract header height */
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
  }

  &.hidden-project {
    opacity: 0;
    max-height: 120px;
    overflow: hidden;
  }
}

.project-content {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  opacity: 0; /* Start invisible */
  transition: opacity 0.5s ease-in-out;

  /* In expanded view, make it take the full height */
  .project-row.expanded & {
    max-height: calc(100vh - 25px); /* Ensure content doesn't exceed viewport minus header */
  }

  &.fade-in {
    opacity: 1;
  }
}

.spinner-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.images-scroll-container {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 100%;
  position: relative;
  max-width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }

  &.minimized {
    overflow: auto;
  }

  &:not(.minimized) {
    overflow-x: auto;
    overflow-y: hidden;

    /* In expanded view, fill the remaining space after ProjectInfo */
    .project-row.expanded & {
      flex: 1; /* Take all remaining space */
      max-height: 100%; /* Prevent overflow */
    }
  }
}

.images-container {
  height: calc(15vh - 100px);
  min-height: 70px;
  display: inline-flex;
  gap: 0 !important;
  will-change: transform;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: max-content;
  position: relative;

  /* In expanded view, fit within available space */
  .project-row.expanded & {
    height: 100%;
    max-height: 100%; /* Ensure it doesn't overflow */
  }

  &.fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
}

.image-wrapper {
  flex: 0 0 auto;
  display: inline-block;
  height: 100%;
  position: relative;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  /* In expanded view, ensure images fit within container */
  &.expanded {
    margin-right: 20px;
    height: 100%;
    object-fit: contain;
    width: auto;
  }
}

@media (max-width: 769px) {
  .image-wrapper {
    margin-right: 2px;
    &.expanded {
        margin-right: 20px;
    }
  }
}


.gallery-image {
  height: 100%;
  object-fit: cover;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: auto;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.gallery-image.expanded {
  object-fit: contain;
  max-height: 100%;
}

.load-more-trigger {
  height: 1px;
  width: 100%;
  background-color: transparent;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.project-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.fade-in { opacity: 1; }
}

.images-container {
  &.fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
}

.inline-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #000;
  animation: spin 1s ease infinite;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000; /* Black color as per your design */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s ease infinite;
}
@keyframes spin {
  to { transform: rotate(360deg); }
}
</style>