// imageGalleryUtils.js

const GAP = 20;

/**
 * Calculates display width based on aspect ratio
 * @param {Object} image - Image object with attributes: { width, height }
 * @param {boolean} expanded - Whether the image is in expanded view
 * @returns {number} - Display width in pixels
 */
export const calculateImageDisplayWidth = (image, expanded) => {
    const aspectRatio = image.attributes.width / image.attributes.height;
    const viewportHeight = window.innerHeight;
    const displayedHeight = expanded
        ? (viewportHeight - 40)    // Expanded view
        : (viewportHeight * 0.15); // Thumbnail

    return Math.round(displayedHeight * aspectRatio); // Round to prevent sub-pixel issues
};

/**
 * Calculate scroll position for current image
 * @param {Array} images - Array of images
 * @param {number} currentIndex - Current image index
 * @returns {number} - Scroll amount in pixels
 */
export const calculateScrollPosition = (images, currentIndex) => {
    // Check if we're on mobile
    if (window.innerWidth <= 768) {
        return window.innerWidth * currentIndex;
    }

    // Calculate total width of the container
    const totalWidth = images.reduce((sum, image, index) =>
        sum + calculateImageDisplayWidth(image, true) + (index < images.length - 1 ? GAP : 0), 0);

    // If we're on the last image, align to right edge of viewport
    if (currentIndex === images.length - 1) {
        const viewportWidth = window.innerWidth;
        return Math.max(0, totalWidth - viewportWidth);
    }

    // Default behavior for other images
    let position = 0;
    for (let i = 0; i < currentIndex; i++) {
        position += calculateImageDisplayWidth(images[i], true) + GAP;
    }
    return position;
};

export const CONSTANTS = {
    GAP,
    TRANSITION_DURATION: 600,
    TRANSITION_TIMING: 'cubic-bezier(0.4, 0, 0.2, 1)'
};