// src/utils/projectsLoader.js
import { getImageUrl } from '@/utils/imageLoader';

export async function loadCriticalImages(project, clickedIndex) {
    const images = project.attributes.images.data;
    const indices = [];
    if (clickedIndex > 0) indices.push(clickedIndex - 1);
    indices.push(clickedIndex);
    if (clickedIndex < images.length - 1) indices.push(clickedIndex + 1);

    const uniqueIndices = [...new Set(indices)];

    const loadPromises = uniqueIndices.map(i => {
        const image = images[i];
        return new Promise(resolve => {
            const img = new Image();
            img.src = getImageUrl(image, true);
            img.onload = resolve;
            img.onerror = resolve;
        });
    });
    await Promise.all(loadPromises);
}

export async function preloadProjectImages(images) {
    const loadPromises = images.map(image => {
        return new Promise(resolve => {
            const img = new Image();
            img.src = getImageUrl(image, true);
            img.onload = resolve;
            img.onerror = resolve;
        });
    });
    await Promise.all(loadPromises);
}

// Function to handle initial project loading
export async function loadInitialProjects(fetchProjects) {
    try {
        const allProjects = await fetchProjects();

        // Return first 3 projects initially
        const initialProjects = allProjects.slice(0, 3);

        return {
            initialProjects,
            allProjects
        };
    } catch (error) {
        console.error('Failed to fetch projects:', error);
        throw error;
    }
}

// Function to preload adjacent project images
export async function preloadAdjacentProjects(sortedProjects, currentProject) {
    const currentIndex = sortedProjects.findIndex(p => p.id === currentProject.id);

    // Get previous and next projects
    const prevProject = currentIndex > 0 ? sortedProjects[currentIndex - 1] : null;
    const nextProject = currentIndex < sortedProjects.length - 1 ? sortedProjects[currentIndex + 1] : null;

    const preloadTasks = [];

    if (prevProject) {
        const lastThreeImages = prevProject.attributes.images.data.slice(-3);
        preloadTasks.push(preloadProjectImages(lastThreeImages));
    }

    if (nextProject) {
        const firstThreeImages = nextProject.attributes.images.data.slice(0, 3);
        preloadTasks.push(preloadProjectImages(firstThreeImages));
    }

    await Promise.all(preloadTasks);
}