import { ref, computed, nextTick, watch } from 'vue';
import { getFullUrl } from '@/api';
import { CONSTANTS } from '@/utils/imageGalleryUtills';
import { useProjectStore } from '@/stores/projects';
import { loadCriticalImages } from '@/utils/projectsLoader';

export function useGallery(projects, scrollContainerRef) {
    const projectStore = useProjectStore();
    // Local state
    const isExpanded = ref(false);
    const currentProjectIndex = ref(null);
    const currentImageIndex = ref(null);
    const isTransitioning = ref(false);
    const scrollEdgeThreshold = 50; // Pixels from edge to trigger project nav
    const navigationCooldown = 500; // Milliseconds between nav actions
    let lastNavTime = 0;
    const isScrollingProgrammatically = ref(false);

    // Touch navigation state
    const touchStartX = ref(0);
    const touchEndX = ref(0);
    const minSwipeDistance = 50; // Minimum distance required for a swipe
    const isTouching = ref(false);

    // Get the active projects - safely handle different project sources
    const activeProjects = computed(() => {
        // First check if we have valid projectStore.visibleProjects
        if (projectStore.visibleProjects && projectStore.visibleProjects.length > 0) {
            return projectStore.visibleProjects;
        }

        // Check if we have valid projects array
        if (Array.isArray(projects) && projects.length > 0) {
            return projects;
        }

        // Return empty array as fallback
        return [];
    });

    // Safe computed values that handle null/undefined
    const currentProject = computed(() => {
        if (currentProjectIndex.value === null || !activeProjects.value.length) return null;
        return activeProjects.value[currentProjectIndex.value] || null;
    });

    const currentImages = computed(() => {
        if (!currentProject.value || !currentProject.value.attributes || !currentProject.value.attributes.images) {
            return [];
        }
        return currentProject.value.attributes.images.data || [];
    });

    // Check if project is in critical loading state
    const isCriticalLoading = (projectId) => {
        if (!projectId) return false;
        return projectStore.isProjectLoading ? projectStore.isProjectLoading(projectId) : false;
    };

    // Preload helpers - reusing existing code
    const preloadAdjacentProjectImages = async (direction) => {
        if (!currentProject.value) return;

        try {
            // Use the new targeted preload function
            await projectStore.preloadAdjacentProject(
                direction,
                currentProject.value.id
            );
        } catch (error) {
            console.warn('Error preloading adjacent project images:', error);
        }
    };


    const getImageUrl = (image, highRes = false) => {
        if (highRes && image.attributes.url) return getFullUrl(image.attributes.url);
        if (image.attributes.formats?.thumbnail) return getFullUrl(image.attributes.formats.thumbnail.url);
        return getFullUrl(image.attributes.url);
    };

    const projectClasses = computed(() => (pIndex) => {
        if (pIndex === undefined || pIndex === null) return {};

        return {
            expanded: isExpanded.value && currentProjectIndex.value === pIndex,
            hidden: isExpanded.value && currentProjectIndex.value !== pIndex,
            'fade-out': isTransitioning.value && currentProjectIndex.value !== pIndex,
            'fade-in': currentProjectIndex.value === pIndex
        };
    });

    const infoClasses = computed(() => (pIndex) => {
        if (pIndex === undefined || pIndex === null) return {};

        return {
            'project-info-expanded': isExpanded.value && currentProjectIndex.value === pIndex,
            'project-info-list': !isExpanded.value || currentProjectIndex.value !== pIndex
        };
    });

    // Throttle function to limit how often a function can be called
    function throttle(callback, delay) {
        let lastCall = 0;
        return function(...args) {
            const now = Date.now();
            if (now - lastCall >= delay) {
                lastCall = now;
                callback.apply(this, args);
            }
        };
    }

    // Function to handle scroll events and update currentImageIndex
    // Update your handleScroll function
    const handleScroll = throttle(() => {
        if (!isExpanded.value || isTransitioning.value || isScrollingProgrammatically.value) return;

        const container = document.querySelector('.project-row.expanded .images-scroll-container');
        if (!container) return;

        // Force index reset when at edges
        if (container.scrollLeft <= 5) {
            currentImageIndex.value = 0;
            return;
        } else if (container.scrollLeft + container.clientWidth >= container.scrollWidth - 5) {
            currentImageIndex.value = currentImages.value.length - 1;
            return;
        }

        const imageWrappers = Array.from(container.querySelectorAll('.image-wrapper'));
        if (!imageWrappers.length) return;

        // Calculate center point of visible container area
        const containerLeft = container.scrollLeft;
        const containerWidth = container.clientWidth;
        const containerCenter = containerLeft + (containerWidth / 2);

        // Find which image is most visible in the viewport
        let bestVisibleIndex = 0;
        let bestVisibleScore = -Infinity;

        imageWrappers.forEach((wrapper, index) => {
            const imageLeft = wrapper.offsetLeft;
            const imageWidth = wrapper.offsetWidth;
            const imageRight = imageLeft + imageWidth;

            // Calculate how much of the image is visible and how centered it is
            const visibleLeft = Math.max(containerLeft, imageLeft);
            const visibleRight = Math.min(containerLeft + containerWidth, imageRight);

            // Skip if image is not visible at all
            if (visibleRight <= visibleLeft) return;

            // Calculate visible area and center proximity
            const visibleWidth = visibleRight - visibleLeft;
            const imageCenter = imageLeft + (imageWidth / 2);
            const centerProximity = 1 - (Math.abs(containerCenter - imageCenter) / containerWidth);

            // Scoring: combination of visible area and center alignment
            const visibilityScore = (visibleWidth / imageWidth) * 0.5 + centerProximity * 0.5;

            if (visibilityScore > bestVisibleScore) {
                bestVisibleScore = visibilityScore;
                bestVisibleIndex = index;
            }
        });

        // Only update if the index has changed
        if (bestVisibleIndex !== currentImageIndex.value) {
            currentImageIndex.value = bestVisibleIndex;
        }
    }, 50);

    // Touch event handlers
    // Touch event handlers
    const handleTouchStart = (event) => {
        if (!isExpanded.value) return;
        touchStartX.value = event.touches[0].clientX;
        isTouching.value = true;
    };

    const handleTouchMove = (event) => {
        if (!isExpanded.value || !isTouching.value) return;
        touchEndX.value = event.touches[0].clientX;
    };

    // Replace your handleTouchEnd function with this version
    // Update handleTouchEnd to strictly check edge conditions
    const handleTouchEnd = (event) => {
        if (!isExpanded.value || !isTouching.value) return;
        isTouching.value = false;

        // Check if we should handle project navigation
        const container = document.querySelector('.project-row.expanded .images-scroll-container');
        if (!container) return;

        // Calculate swipe distance
        const swipeDistance = touchEndX.value - touchStartX.value;
        const absSwipeDistance = Math.abs(swipeDistance);

        // Only process if it's a significant swipe
        if (absSwipeDistance < minSwipeDistance) return;

        // STRICT edge check - only navigate projects when truly at the edge
        const isAtLeftEdge = container.scrollLeft <= 5;
        const isAtRightEdge = container.scrollLeft + container.clientWidth >= container.scrollWidth - 5;

        // Check cooldown to prevent rapid navigation
        if (Date.now() - lastNavTime < navigationCooldown) return;

        // Handle left swipe (next project) ONLY when at right edge AND on last image
        if (swipeDistance < 0 && isAtRightEdge && currentImageIndex.value === currentImages.value.length - 1) {
            navigate('next');
            lastNavTime = Date.now();
        }
        // Handle right swipe (previous project) ONLY when at left edge AND on first image
        else if (swipeDistance > 0 && isAtLeftEdge && currentImageIndex.value === 0) {
            navigate('previous');
            lastNavTime = Date.now();
        }

        // Reset touch coordinates
        touchStartX.value = 0;
        touchEndX.value = 0;
    };

    const handleWheel = (event) => {
        if (!isExpanded.value) return;

        // Check cooldown and current position
        const isFirstImage = currentImageIndex.value === 0;
        const isLastImage = currentImageIndex.value === (currentImages.value?.length || 0) - 1;

        if (Date.now() - lastNavTime < navigationCooldown) return;

        // Only allow navigation when at edges
        if (event.deltaX > 50 && isLastImage) { // Right swipe at last image
            navigate('next');
            lastNavTime = Date.now();
        } else if (event.deltaX < -50 && isFirstImage) { // Left swipe at first image
            navigate('previous');
            lastNavTime = Date.now();
        }

        // Allow native scrolling for middle images
    };

    const navigate = async (direction) => {
        if (!isExpanded.value || isTransitioning.value) return;
        isTransitioning.value = true;

        try {
            const oldContainer = scrollContainerRef.value;

            // Preload the adjacent project before navigating
            await preloadAdjacentProjectImages(direction);

            if (direction === 'next') {
                await handleNextNavigation();
            } else {
                await handlePreviousNavigation();
            }

            await nextTick();

            // Get new container reference after project change
            const newContainer = scrollContainerRef.value;
            if (newContainer) {
                // Reset scroll position for new project
                newContainer.scrollLeft = direction === 'next' ? 0 : newContainer.scrollWidth;
            }
        } finally {
            isTransitioning.value = false;
        }
    };


    function disableScrollAnimation() {
        // Disable all transition and animation CSS properties for images container
        const containers = document.querySelectorAll('.images-scroll-container');
        containers.forEach(container => {
            container.style.scrollBehavior = 'auto';
            container.style.transition = 'none';
        });

        // Also disable transitions on image wrappers
        const imageWrappers = document.querySelectorAll('.image-wrapper');
        imageWrappers.forEach(wrapper => {
            wrapper.style.transition = 'none';
        });
    }

    function enableScrollAnimation() {
        // Re-enable transitions
        const containers = document.querySelectorAll('.images-scroll-container');
        containers.forEach(container => {
            container.style.scrollBehavior = 'smooth';
            container.style.transition = ''; // Restore default transitions
        });

        // Re-enable transitions on image wrappers
        const imageWrappers = document.querySelectorAll('.image-wrapper');
        imageWrappers.forEach(wrapper => {
            wrapper.style.transition = ''; // Restore default transitions
        });
    }

    // Replace the jumpToLastImage function with this corrected version:

    function jumpToLastImage(projectIndex, imageIndex) {
        // Check for valid inputs
        if (projectIndex === null || projectIndex === undefined) return;

        // Get the specific container for the target project
        const projectSelector = `.project-row:nth-child(${projectIndex + 1})`;
        const container = document.querySelector(`${projectSelector} .images-scroll-container`);

        if (!container) return;

        // Get all images in this project
        const imageWrappers = container.querySelectorAll('.image-wrapper');

        if (!imageWrappers.length) return;

        // Get the target image, defaulting to last available if index is out of bounds
        const targetIndex = Math.min(imageIndex, imageWrappers.length - 1);
        const imageWrapper = imageWrappers[targetIndex];

        if (!imageWrapper) return;

        // For the last image, we want to scroll to the maximum right position
        // This will ensure the last image's right edge is visible
        if (targetIndex === imageWrappers.length - 1) {
            // Calculate maximum possible scroll position
            const maxScroll = container.scrollWidth - container.clientWidth;
            container.scrollLeft = maxScroll;
        } else {
            // For all other images, just scroll to their left edge
            container.scrollLeft = imageWrapper.offsetLeft;
        }

        // Double check scroll position after a small delay
        setTimeout(() => {
            if (targetIndex === imageWrappers.length - 1) {
                // Force maximum scroll again to ensure right alignment
                const maxScroll = container.scrollWidth - container.clientWidth;
                container.scrollLeft = maxScroll;
            }
        }, 50);
    }

    async function handlePreviousNavigation() {
        // Check if we have valid images and projects to navigate
        if (!currentImages.value || !activeProjects.value || !activeProjects.value.length) {
            console.warn('No valid images or projects to navigate');
            return;
        }

        // If not on first image of current project, simply go to previous image
        if (currentImageIndex.value > 0) {
            currentImageIndex.value--;
            scrollToImage();
            return;
        }

        // We're on the first image and need to go to previous project
        const prevProjectIndex = currentProjectIndex.value > 0
            ? currentProjectIndex.value - 1
            : activeProjects.value.length - 1;

        // Get the previous project data
        const prevProject = activeProjects.value[prevProjectIndex];

        if (!prevProject) {
            console.warn('prevProject not found');
            return;
        }

        // Safely access the images
        const prevImages = prevProject.attributes?.images?.data || [];

        // Determine the last image index of the previous project
        const lastImageIndex = prevImages.length - 1;

        if (lastImageIndex < 0) {
            console.warn('No images in previous project');
            return;
        }

        // Preload the last image of the previous project
        await projectStore.loadCriticalImages?.(prevProject, lastImageIndex);

        // Important: Disable transitions to ensure smooth navigation
        disableScrollAnimation();

        // Update project index first
        currentProjectIndex.value = prevProjectIndex;
        await nextTick();

        // Set the image index to the last image
        currentImageIndex.value = lastImageIndex;
        await nextTick();

        // Force scroll directly to the target image
        jumpToLastImage(prevProjectIndex, lastImageIndex);

        // Re-enable transitions after a short delay
        setTimeout(() => {
            enableScrollAnimation();
        }, 200);
    }

// 3. Ensure handleNextNavigation behaves consistently
    async function handleNextNavigation() {
        // Check if we have valid images to navigate
        if (!currentImages.value || !activeProjects.value) {
            console.warn('No valid images or projects to navigate');
            return;
        }

        // If not on last image of current project, simply go to next image
        if (currentImageIndex.value < currentImages.value.length - 1) {
            currentImageIndex.value++;
            nextTick(() => scrollToImage());
            return;
        }

        // We're on the last image and need to go to next project
        // Make sure we have a valid activeProjects array
        if (!activeProjects.value.length) return;

        const nextProjectIndex = currentProjectIndex.value < activeProjects.value.length - 1
            ? currentProjectIndex.value + 1
            : 0;

        // Preload the first image of the next project
        if (activeProjects.value[nextProjectIndex]) {
            await projectStore.loadCriticalImages?.(
                activeProjects.value[nextProjectIndex],
                0
            );
        }

        // Disable animations briefly
        disableScrollAnimation();

        // Update project index and reset image index
        currentProjectIndex.value = nextProjectIndex;
        currentImageIndex.value = 0;

        await nextTick();

        // Force scroll to first image
        const container = document.querySelector(`.project-row:nth-child(${nextProjectIndex + 1}) .images-scroll-container`);
        if (container) {
            container.scrollLeft = 0;
        }

        // Re-enable transitions after a short delay
        setTimeout(() => {
            enableScrollAnimation();
        }, 200);
    }

    function toggleExpand(pIndex) {
        if (currentProjectIndex.value !== pIndex) {
            currentProjectIndex.value = pIndex;
            currentImageIndex.value = 0;
            isExpanded.value = true;
        } else {
            isExpanded.value = !isExpanded.value;
        }
        if (isExpanded.value) nextTick(() => scrollToImage());
    }

    function selectImage(pIndex, iIndex) {
        if (isExpanded.value) {
            return;
        }

        const clickedImageIndex = iIndex;
        const wasAlreadyExpanded = isExpanded.value;

        if (!wasAlreadyExpanded) {
            disableScrollAnimation();
        }

        currentProjectIndex.value = pIndex;
        currentImageIndex.value = clickedImageIndex;

        if (!wasAlreadyExpanded) {
            isExpanded.value = true;
            nextTick(() => {
                // Directly scroll to the image in the expanded container
                directScrollToImage(clickedImageIndex);
                // Re-enable smooth scrolling after the expansion transition completes
                setTimeout(() => enableScrollAnimation(), CONSTANTS.TRANSITION_DURATION);
            });
        } else {
            nextTick(() => directScrollToImage(clickedImageIndex));
        }
    }

    function directScrollToImage(imageIndex) {
        // Get the current expanded project container
        const container = document.querySelector('.project-row.expanded .images-scroll-container');
        if (!container) {
            console.error("No expanded container found");
            return;
        }

        // Get target image wrapper
        const imageWrappers = Array.from(container.querySelectorAll('.image-wrapper'));
        if (!imageWrappers.length) {
            console.error("No image wrappers found");
            return;
        }

        // Get the specific target image
        const targetImageWrapper = imageWrappers[imageIndex];
        if (!targetImageWrapper) {
            console.error(`No image at index ${imageIndex}`);
            return;
        }

        // Calculate target position (image left edge)
        const targetPosition = targetImageWrapper.offsetLeft;

        // Set scroll position - with smooth behavior if enabled
        container.scrollLeft = targetPosition;

        // Secondary check to ensure scrolling took effect
        setTimeout(() => {
            if (Math.abs(container.scrollLeft - targetPosition) > 5) {
                container.scrollLeft = targetPosition;
            }
        }, 50);
    }

    // Improved scrollToImage with flag to prevent scroll event triggering index update
    function scrollToImage() {
        const container = document.querySelector('.project-row.expanded .images-scroll-container');
        if (!container) return;

        // Find target image
        const imageWrappers = Array.from(container.querySelectorAll('.image-wrapper'));
        if (!imageWrappers.length || currentImageIndex.value >= imageWrappers.length) return;

        const targetImage = imageWrappers[currentImageIndex.value];
        if (!targetImage) return;

        const targetPosition = targetImage.offsetLeft;

        // Set flag to ignore scroll events during programmatic scrolling
        isScrollingProgrammatically.value = true;

        // Scroll to the target image position
        container.scrollTo({
            left: targetPosition,
            behavior: 'smooth'
        });

        // Reset flag after scrolling completes
        setTimeout(() => {
            isScrollingProgrammatically.value = false;
        }, 500); // Slightly longer than transition duration
    }

    function handleResize() {
        if (isExpanded.value) {
            scrollToImage();
        }
    }

    // Watch for changes to preload images for edge cases
    watch([currentProjectIndex, currentImageIndex], ([projectIndex, imageIndex]) => {
        if (projectIndex === null || imageIndex === null) return;

        // If we're at the first image of a project, preload the last image of the previous project
        if (imageIndex === 0) {
            preloadAdjacentProjectImages('previous');
        }

        // If we're at the last image of a project, preload the first image of the next project
        if (imageIndex === (currentImages.value?.length || 0) - 1) {
            preloadAdjacentProjectImages('next');
        }
    });

    return {
        isExpanded,
        currentProjectIndex,
        currentImageIndex,
        currentProject,
        currentImages,
        isTransitioning,
        projectClasses,
        infoClasses,
        getImageUrl,
        navigate,
        toggleExpand,
        selectImage,
        handleResize,
        handleWheel,
        handleScroll,
        disableScrollAnimation,
        enableScrollAnimation,
        jumpToLastImage,
        isCriticalLoading,
        // Touch event handlers
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd
    };
}