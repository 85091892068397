// stores/projects.js
import { defineStore } from 'pinia';
import { fetchProjects } from '@/api';
import { CacheManager, cacheKeys } from '@/utils/cache';
import { loadCriticalImages, preloadAdjacentProjects } from '@/utils/projectsLoader';
import { sortProjectsByDate } from '@/api';

export const useProjectStore = defineStore('projectStore', {
    state: () => ({
        projects: null,
        visibleProjects: [],
        cachedThumbnails: {},
        loading: false,
        error: null,
        criticalLoadingProjects: new Set(),
        projectsWithLoadedCriticalImages: new Set()
    }),

    getters: {
        sortedProjects: (state) => {
            if (!state.projects) return [];
            return sortProjectsByDate(state.projects);
        },

        isProjectLoading: (state) => (projectId) => {
            if (!projectId) return false;
            return state.criticalLoadingProjects.has(projectId);
        }
    },

    actions: {
        async loadProjects() {
            // Return memory-cached projects if available
            if (this.projects) {
                return this.projects;
            }

            this.loading = true;
            this.error = null;

            try {
                // Check for cached projects in localStorage first
                let cachedData = null;
                try {
                    cachedData = localStorage.getItem(cacheKeys.PROJECTS);
                    if (cachedData) {
                        cachedData = JSON.parse(cachedData);
                    }
                } catch (e) {
                    console.warn('Error parsing cached projects:', e);
                    cachedData = null;
                }

                if (cachedData && cachedData.data && cachedData.timestamp) {
                    // Use cache if less than 24 hours old
                    if (Date.now() - cachedData.timestamp < 86400000) {
                        this.projects = cachedData.data;
                        // Initialize visible projects
                        this.initVisibleProjects();
                        return this.projects;
                    }
                }

                // If no valid cache, fetch from API
                this.projects = await fetchProjects();

                // Cache the response
                try {
                    localStorage.setItem(cacheKeys.PROJECTS, JSON.stringify({
                        data: this.projects,
                        timestamp: Date.now()
                    }));
                } catch (storageError) {
                    // Handle quota exceeded errors
                    if (storageError.name === 'QuotaExceededError') {
                        localStorage.clear();
                    }
                    console.warn('Error caching projects:', storageError);
                }

                // Initialize visible projects
                this.initVisibleProjects();

                return this.projects;
            } catch (error) {
                this.error = error.message;
                throw error;
            } finally {
                this.loading = false;
            }
        },

        // Initialize visible projects (first batch)
        initVisibleProjects() {
            if (this.projects && this.projects.length > 0) {
                const sorted = sortProjectsByDate(this.projects);
                // Reduce this initial load to 3 projects (or whatever number you prefer)
                this.visibleProjects = sorted.slice(0, 3);
                console.log('Initialized visible projects:', this.visibleProjects.length);
            }
        },

        // Load more projects for infinite scrolling
        loadMoreProjects(batchSize = 3) {
            if (!this.projects || this.visibleProjects.length >= this.projects.length) {
                return;
            }

            const sorted = sortProjectsByDate(this.projects);
            const currentCount = this.visibleProjects.length;
            const nextBatch = sorted.slice(currentCount, currentCount + batchSize);

            // Add more projects
            this.visibleProjects = [...this.visibleProjects, ...nextBatch];

            return nextBatch; // Return the newly loaded batch
        },

        // Add this method to manage visibility through the gallery
        ensureProjectVisible(projectId) {
            // If project is already visible, do nothing
            if (this.visibleProjects.some(p => p.id === projectId)) {
                return;
            }

            // Find project in all projects
            const targetProject = this.projects.find(p => p.id === projectId);
            if (!targetProject) return;

            // Add just this one project to visible projects
            this.visibleProjects = [...this.visibleProjects, targetProject];
        },


        // Load critical images for a project (when expanding/viewing)
        // Load critical images for a project (when expanding/viewing)
        async loadCriticalImages(project, imageIndex) {
            if (!project || !project.id) {
                console.warn('Invalid project passed to loadCriticalImages');
                return;
            }

            // Skip if already loaded
            if (this.projectsWithLoadedCriticalImages.has(project.id)) {
                return;
            }

            // Mark as loading
            this.criticalLoadingProjects.add(project.id);

            try {
                // Ensure this project is visible
                this.ensureProjectVisible(project.id);

                // Load critical images
                await loadCriticalImages(project, imageIndex);

                // Mark as loaded
                this.projectsWithLoadedCriticalImages.add(project.id);

                // Cache thumbnails if needed
                if (project.attributes?.images?.data) {
                    this.cacheThumbnails(project.id, project.attributes.images.data);
                }
            } catch (error) {
                console.error('Error loading critical images:', error);
            } finally {
                // Remove loading state
                this.criticalLoadingProjects.delete(project.id);
            }
        },

        async preloadAdjacentProject(direction, currentProjectId) {
            if (!this.projects || !currentProjectId) return;

            const sorted = sortProjectsByDate(this.projects);
            const currentIndex = sorted.findIndex(p => p.id === currentProjectId);

            if (currentIndex === -1) return;

            let targetIndex;
            if (direction === 'next') {
                targetIndex = currentIndex + 1;
                if (targetIndex >= sorted.length) {
                    // Loop back to beginning for endless scrolling
                    targetIndex = 0;
                }
            } else { // 'previous'
                targetIndex = currentIndex - 1;
                if (targetIndex < 0) {
                    // Loop to end for endless scrolling
                    targetIndex = sorted.length - 1;
                }
            }

            const targetProject = sorted[targetIndex];
            if (!targetProject) return;

            // Ensure this project is in visible projects if not already
            if (!this.visibleProjects.some(p => p.id === targetProject.id)) {
                // Add this project to visible projects
                this.visibleProjects = [...this.visibleProjects, targetProject];

                // Log for debugging
                console.log(`Added project ${targetProject.id} to visible projects. Total visible: ${this.visibleProjects.length}`);
            }

            // Load critical images for target project
            const imageIndex = direction === 'next' ? 0 :
                (targetProject.attributes?.images?.data?.length - 1) || 0;

            return this.loadCriticalImages(targetProject, imageIndex);
        },

        clearCache() {
            this.projects = null;
            this.visibleProjects = [];
            this.cachedThumbnails = {};
            this.projectsWithLoadedCriticalImages.clear();
            localStorage.removeItem(cacheKeys.PROJECTS);

            if (CacheManager && typeof CacheManager.clear === 'function') {
                CacheManager.clear(cacheKeys.PROJECTS);
            }
        },

        cacheThumbnails(projectId, thumbnails) {
            this.cachedThumbnails[projectId] = thumbnails;
        },

        getCachedThumbnails(projectId) {
            return this.cachedThumbnails[projectId] || null;
        }
    }
});